import 'jquery/dist/jquery.min'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './scripts/prevent-multiple-form-submissions'
import {initIntlInput} from "./scripts/intl-tel-input-initializer";
import {initPreventMultipleFormSubmissions} from "./scripts/prevent-multiple-form-submissions";
import {
    addPhoneValidation,
    addPinInputValidation,
    addTermsValidation,
    tagPhoneValidation,
    tagPinValidation,
    tagTermsValidation,
    validateForm
} from "./scripts/validation/formValidation";

function ready(fn) {
    if (document.readyState !== 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

function clearClientCertificateOnIE() {
    try {
        document.execCommand("ClearAuthenticationCache", "false");
    } catch (e) {
        // do nothing
    }
}

function clearClientCertificateOnFF() {
    try {
        if (window.crypto && typeof window.crypto.logout === "function") {
            window.crypto.logout();
        }
    } catch (e) {
        // do nothing
    }
}

function clearClientCertificateOnChromium(clearPath) {
    var request = new XMLHttpRequest();
    request.open('GET', clearPath);
    request.send();
}

function clearCertificateCredentials(clearPath) {
    clearClientCertificateOnIE();
    clearClientCertificateOnFF();
    clearClientCertificateOnChromium(clearPath);
}

function abortCniAuthOnTimeout(authTimeoutSeconds, authTimeoutErrorPath) {
    setTimeout(function () {
        window.location.href = authTimeoutErrorPath;
    }, authTimeoutSeconds);
}


window.addEventListener("DOMContentLoaded", function (e) {
    let forms = document.getElementsByTagName("form")

    for (let i = 0; i < forms.length; i++) {
        console.debug(`adding form event listener to form ${forms[i].id}`)

        const form = forms[i];

        let preventMultipleFormSubmissionsHandler = initPreventMultipleFormSubmissions()


        let inputs = form.getElementsByTagName("input")

        let phoneValidationFunction = undefined
        for (let j = 0; j < inputs.length; j++) {
            let input = inputs[j]
            if (input.hasAttribute(tagPinValidation)) {
                addPinInputValidation(input)
            }else if (input.hasAttribute(tagTermsValidation)) {
                addTermsValidation(input)
            }else if (input.hasAttribute(tagPhoneValidation)) {
                let iti = initIntlInput(input.id)
                phoneValidationFunction = addPhoneValidation(input, iti)
            }
        }

        form.addEventListener("submit", function (event) {
            if (validateForm(event, phoneValidationFunction)) {
                preventMultipleFormSubmissionsHandler(event)
            }else{
                event.preventDefault();
            }
        })
    }

})


window.ready = ready
window.abortCniAuthOnTimeout = abortCniAuthOnTimeout;
window.clearCertificateCredentials = clearCertificateCredentials;
window.initIntlInput = initIntlInput