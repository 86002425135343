const configuration = {
    messages: {
        missing: "Campo obrigatório",
        length: (l) => `Falta inserir mais ${6 - l} dígito${l === 5 ? '' : 's'}`,
        pattern: "Insira apenas números",
        mustAcceptTerns: "Aceite os termos e condições"
    },
    styles: {
        invalidClass: 'is-invalid',
        validClass: 'is-valid',
        invalidFeedbackClass: 'invalid-feedback'
    }
};

const tagPinValidation = "data-validate-pin"
const tagTermsValidation = "data-validate-terms"
const tagPhoneValidation = "data-validate-phone"

function markFormAsValidated(form) {
    let wasValidatedClass = "was-validated";

    if (!form.classList.contains(wasValidatedClass)) {
        form.classList.add(wasValidatedClass);
    }
}

function getErrorMessage(validity, value) {
    if (validity.valueMissing) {
        return configuration.messages.missing
    } else if (validity.patternMismatch) {
        // set wrong pattern message
        return configuration.messages.pattern
    } else if (validity.tooLong) {
        return configuration.messages.length(value.length)
    } else if (validity.tooShort) {
        return configuration.messages.length(value.length)
    }
}

function createErrorDiv(message) {
    let htmlDivElement = document.createElement("div");
    htmlDivElement.classList.add(configuration.styles.invalidFeedbackClass);
    htmlDivElement.innerText = message;
    return htmlDivElement;
}

function addErrorMessage(element, message) {
    let errorElements = element.parentElement.getElementsByClassName("invalid-feedback");

    // remove all the previous error messages
    if (errorElements.length > 0) {

        errorElements[0].innerText = message

        if (errorElements.length > 1) {
            for (let i = 0; i < errorElements.length; i++) {
                errorElements[i].remove()
            }
        }

    } else {
        element.insertAdjacentElement("afterend", createErrorDiv(message));
    }

}

function addPinInputValidation(element) {

    element.addEventListener('input', () => {
        element.classList.remove(configuration.styles.invalidClass)
        element.setCustomValidity('');
        if (element.checkValidity()) {
            element.classList.add(configuration.styles.validClass)
        }
    });

    element.addEventListener('invalid', () => {
            let validity = element.validity;

            if (validity.valid) {
                return;
            }

            element.classList.remove("is-valid")
            element.classList.add("is-invalid")
            let errorMessage = getErrorMessage(validity, element.value);

            addErrorMessage(element, errorMessage);

        }
    );
}

function addTermsValidation(element) {
    const validate = () => {
        if (element.validity.valueMissing) {
            element.setCustomValidity(configuration.messages.mustAcceptTerns);
        } else {
            element.setCustomValidity('')
        }
    }
    element.addEventListener('input', validate);
    validate()
}

/**
 *
 * @param element the input element
 * @param iti the intlTelInput object initialized with $element
 * @returns {function(): boolean} a validation function that needs to be used during the form onsubmit callback to
 * validate this input
 */
function addPhoneValidation(element, iti) {

    const errorMessage = "Número de telemóvel inválido";

    let invalidFeedbackElement = document.querySelector(`#phone-invalid-feedback`)

    if (invalidFeedbackElement === null) {
        invalidFeedbackElement = document.createElement("div")
        invalidFeedbackElement.classList.add('invalid-feedback')
        element.parentElement.insertAdjacentElement("afterend", invalidFeedbackElement)
    }

    let reset = function () {
        element.classList.remove("is-valid", "is-invalid");
        element.parentElement.classList.remove("is-valid", "is-invalid");
        invalidFeedbackElement.classList.add("d-none");
    };

    let showErrors = false

    element.addEventListener('blur', function () {
        if (!showErrors) {
            showErrors = true;
            validate()
        }
    })

    function validate() {
        reset();
        if (iti.isValidNumber()) {
            element.classList.add("is-valid");
            element.parentElement.classList.remove("is-invalid");

            element.setCustomValidity('')
            showErrors = true
            return true;
        } else if (showErrors) {
            element.classList.add("is-invalid");
            element.parentElement.classList.add("is-invalid");
            invalidFeedbackElement.innerHTML = errorMessage;
            invalidFeedbackElement.classList.remove("d-none");


            // even though the validation is not being done by the validation api, it is required that
            // an error is set in order to avoid the browser assuming that the field is valid.
            element.setCustomValidity('invalid phone')

            return false;
        }
    }

    element.addEventListener('input', validate);

    element.addEventListener("countrychange", function() {
        console.debug(`country changed`)
        validate()
    });
    return () => {
        showErrors = true;
        return validate()
    }
}


function validateForm(e, phoneValidationFunction) {

    let form = e.target;

    if (e.submitter.hasAttribute("formnovalidate")) {
        return true
    } else {

        markFormAsValidated(form);

        let formValid = form.checkValidity()

        if (phoneValidationFunction !== undefined) {
            formValid = phoneValidationFunction() && formValid
        }

        return formValid;
    }
}

export {
    validateForm,
    addTermsValidation,
    addPinInputValidation,
    addPhoneValidation,
    tagPhoneValidation,
    tagTermsValidation,
    tagPinValidation
}